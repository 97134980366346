import slibe1Image from '../../assets/images/home-container-1.svg';
import slide2Step1Calendar from '../../assets/images/slide2-step1-calendar.svg';
import slide2Step2Pay from '../../assets/images/slid2-step2-pay.svg';
import slide2Step3GetCleaned from '../../assets/images/slide2-step3-get-cleaned.svg';
import serviceTypesImage from '../../assets/images/service-types.svg';
import './Home.scss';
import ServiceType from './service-type/ServiceType';
import FormNavigationButtons from './form-navigation-buttons/FormNavigationButtons';

function Home() {
  return (
    <div className="home-container">
      <div className="slide-1 cleanly-fade-in">
        <div className="text">
          <div className="title">Quality cleaning for your home</div>
          <div className="subtitle">
            Condimentum mauris sit cursus amet id non neque pharetra nulla
            ornare sed facilisis senectus dapibus nibh ultrices eget suscipit
            aliquet et nulla magna lacus penatibus.
          </div>
        </div>
        <FormNavigationButtons />
        <div className="image">
          <img src={slibe1Image} />
        </div>
      </div>
      <div className="slide-2">
        <div className="text">
          <div className="title">How our service works?</div>
          <div className="subtitle">
            Sagittis nibh scelerisque vitae eget vulputate sem elementum sed
            neque nisi felis non ultrices massa id egestas quam velit pretium
            nu.
          </div>
        </div>
        <div className="steps">
          <div className="step">
            <img src={slide2Step1Calendar} alt="Schedule a cleaning" />
            <span className="title">1. Schedule online</span>
            <span className="subtitle">
              Sagittis nibh scelerisque vitae egetolment vulputate sem elementum
              sed n.
            </span>
          </div>
          <div className="step">
            <img src={slide2Step2Pay} alt="Schedule a cleaning" />
            <span className="title">2. Pay online easily</span>
            <span className="subtitle">
              Vitae ut accumsan blandit ullamcorperolm suscipit dui gravida amet
              at nunc.
            </span>
          </div>
          <div className="step">
            <img src={slide2Step3GetCleaned} alt="Schedule a cleaning" />
            <span className="title">3. Get your house cleaned</span>
            <span className="subtitle">
              Nunc maecenas sollicitudin metus tellus mattis sed porttitor
              cursus eleifend.
            </span>
          </div>
        </div>
        <div className="actions">
          <FormNavigationButtons />
        </div>
        <div className="divider"></div>
      </div>
      <div className="service-types-container">
        <div className="header">
          <div className="title">We cover all areas of your home or office</div>
          <div className="description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam.
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <ServiceType name={'Bathrooms'} />
            <ServiceType name={'Kitchens'} />
            <ServiceType name={'Living Rooms'} />
            <ServiceType name={'Carpets'} />
          </div>
          <div className="column">
            <ServiceType name={'Bedrooms'} />
            <ServiceType name={'Offices'} />
            <ServiceType name={'Businesses'} />
            <ServiceType name={'Windows'} />
          </div>
        </div>
        <div className="actions">
          <FormNavigationButtons />
        </div>
        <div className="image">
          <img src={serviceTypesImage} alt="Service types" />
        </div>
      </div>

      <div className="forms">
        <div id="house-owner-form" className="title">
          How big is your house?
        </div>
        <div className="form">
          <div className="form-group">
            <label htmlFor="">House size (m2)</label>
            <input className="cleanly-input" min={1} max={5000} type="number" />
          </div>
          <div className="form-group">
            <label htmlFor="">Cleaning type</label>
            <input className="cleanly-input" type="text" />
          </div>
          <div className="form-group">
            <button className="filled-button">Find a cleaner</button>
          </div>
        </div>
      </div>

      <div className="forms">
        <div id="cleaner-form" className="title">
          What type of cleaning do you offer?
        </div>
        <div className="form">
          <div className="form-group">
            <label htmlFor="">House size (m2)</label>
            <input className="cleanly-input" min={1} max={5000} type="number" />
          </div>
          <div className="form-group">
            <label htmlFor="">Cleaning type</label>
            <input className="cleanly-input" type="text" />
          </div>
          <div className="form-group">
            <button className="outline-button">Find a job</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
