import { Link, useNavigate } from 'react-router-dom';
import './Logo.scss';
import logo from '../../../assets/images/cleanly-logo.svg';

function Logo() {
  const navigate = useNavigate();

  const goToRoot = () => {
    navigate('/');
  };

  return (
    <div onClick={goToRoot} className="logo-wrapper">
      <img className="logo" src={logo} />
      <div className="label">Cleanly</div>
    </div>
  );
}

export default Logo;
