import { FormattedMessage } from 'react-intl';
import './FormNavigationButtons.scss';

function FormNavigationButtons() {
  return (
    <div className="actions-container">
      <a className="find-cleaner" href="#house-owner-form">
        <FormattedMessage id="formButtons.findCleaner"></FormattedMessage>
      </a>
      <a className="find-job" href="#cleaner-form">
      <FormattedMessage id="formButtons.findJob"></FormattedMessage>
      </a>
    </div>
  );
}

export default FormNavigationButtons;
