import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCtyov3jd9ZnUA8gbl6WgZguvH5BJrl2YQ",
  authDomain: "cleanly-landing-dev.firebaseapp.com",
  projectId: "cleanly-landing-dev",
  storageBucket: "cleanly-landing-dev.appspot.com",
  messagingSenderId: "174605409176",
  appId: "1:174605409176:web:ffc449f63f46b7582f69ed",
  measurementId: "G-WHVX50YYKE"
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const analytics = getAnalytics(app);

export { firestore, analytics };
