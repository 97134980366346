import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import messagesEn from './locales/en.json';
import messagesRo from './locales/ro.json';
import messagesRu from './locales/ru.json';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const messages = {
  en: messagesEn,
  ro: messagesRo,
  ru: messagesRu,
} as any;

// const language = navigator.language.split(/[-_]/)[0]; // Get browser language
const language = 'ro';

root.render(
  <BrowserRouter>
    <IntlProvider locale={language} messages={messages[language]}>
      <App />
    </IntlProvider>
  </BrowserRouter>
);
