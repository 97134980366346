import Logo from '../shared/logo/Logo';
import './Footer.scss';

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <div className="footer-container">
      <div className='divider'></div>
      <div className='logo'>
        <Logo/>
      </div>
      <div className="copyright">
        © {currentYear} Cleanly. Toate drepturile rezervate.
      </div>
    </div>
  );
}

export default Footer;
