import serviceTypeIcon from '../../../assets/images/service-type-icon.svg';
import './ServiceType.scss';

export interface IServiceTypeProps {
  name: string;
}

function ServiceType(props: IServiceTypeProps) {
  return (
    <div className="container">
      <img src={serviceTypeIcon} alt="Service Type" />
      <div className="name">{props.name}</div>
    </div>
  );
}

export default ServiceType;
