import { Link } from 'react-router-dom';
import './Header.scss';
import Logo from '../shared/logo/Logo';

function Header() {
  return (
    <div className="header">
      <Logo />
      <div className="navigation">
        {/* <Link to="/about">About</Link> */}
        {/* <Link to="/contact">Contact</Link> */}
      </div>
    </div>
  );
}

export default Header;
