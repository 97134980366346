import { Route, Routes, useLocation } from 'react-router-dom';
import './App.scss';
import { useEffect } from 'react';
import { analytics } from './firebaseConfig';
import { logEvent } from 'firebase/analytics';
import Contact from './components/contact/Contact';
import About from './components/about/About';
import Home from './components/home/Home';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';

function App() {
  const location = useLocation();

  useEffect(() => {
    const logPageView = (url: string) => {
      logEvent(analytics, 'page_view', { page_path: url });
    };

    logPageView(location.pathname + location.search);
  }, [location]);

  return (
    <div className="app">
      <Header />
      <div className="main-content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>

      <Footer />
    </div>
  );
}

export default App;
